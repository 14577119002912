import React, {useEffect, useRef, useState} from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Banner from "../../components/banner"
import {useGoogleReCaptcha} from "react-google-recaptcha-v3"

const axios = require("axios");
const jQuery = require("jquery");

const Contacto = (props) => {
    const isMountedComponent = useRef(true)
    useEffect(() => {
        if (isMountedComponent.current) {
            window.jquery_scripts()
        }
        return () => {
            isMountedComponent.current = false
        }
    })

    const {executeRecaptcha} = useGoogleReCaptcha()
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [subject] = useState("Usuario desea contactar con el administrador del sitio IRSTrat");
    const [message, setMessage] = useState("")
    const [phone, setPhone] = useState("")

    const send = async function (e) {
        e.preventDefault()

        jQuery("#btn-cont").css('pointer-events', 'none')
        jQuery("#btn-cont").html('Enviando...')

        if (!executeRecaptcha) {
            return
        }

        if (email.length === 0 || email.trim().length === 0) {
            return
        } else if (message.length === 0 || message.trim().length === 0) {
            return
        } else if (subject.length === 0 || subject.trim().length === 0) {
            return
        } else if (name.length === 0 || name.trim().length === 0) {
            return
        }
        const result = await executeRecaptcha("contacto")
        const headers = {
            "Content-Type": "application/json"
        }
        axios
            .post(
                "https://us-central1-cloudsites-190318.cloudfunctions.net/mails",
                {
                    name,
                    email,
                    message,
                    subject,
                    phone,
                    token: result
                },
                {
                    headers: headers
                }
            )
            .then(res => {
                jQuery("#contact-form-es").slideUp("slow").hide();
                jQuery("#result").append(
                    '<hr class="mt-4"><div class="success text-center"><h4>¡Correo electrónico enviado con éxito!</h4><br><p>Gracias por usar nuestro formulario de contacto</p></div><hr>'
                )
            })
    }

    return (
        <Layout location={props.location}>
            <SEO title="Contacto"/>
            <Banner title="Contacto"/>
            {/*============Contact area===========*/}
            <section className="contact_area section_gap">
                <div className="container">
                    {/*<div id="mapBox" className="mapBox mb_80" data-lat="40.701083"*/}
                    {/*data-lon="-74.1522848" data-zoom={11} data-marker="img/location.png"*/}
                    {/*data-info="54B, Tailstoi Town 5238 La city, IA 522364" data-mlat="40.701083"*/}
                    {/*data-mlon="-74.1522848">*/}
                    {/*</div>*/}

                    <div className="section_title black_c text-center">
                        <h2>Envíanos un mensaje</h2>
                        {/*<p className="f_16">Praising pain was born and I will give you a complete*/}
                        {/*account of the system, and </p>*/}
                        <span className="sec_border"/>
                    </div>
                    <div id="result"></div>
                    <form className="row contact_form" onSubmit={event => send(event)} method="post"
                          id="contact-form-es">
                        <div className="form-group col-md-4">
                            <input type="text" className="form-control" id="name" name="name"
                                   placeholder="Nombre" onChange={event => setName(event.target.value)} required/>
                        </div>
                        <div className="form-group col-md-4">
                            <input type="email" className="form-control" id="email" name="email"
                                   placeholder="Email" onChange={event => setEmail(event.target.value)} required/>
                        </div>
                        <div className="form-group col-md-4">
                            <input type="text" className="form-control" id="phone" name="phone"
                                   placeholder="Teléfono" onChange={event => setPhone(event.target.value)} required/>
                        </div>
                        <div className="form-group col-md-12">
                                            <textarea className="form-control" name="message" id="message" rows={1}
                                                      placeholder="Mensaje"
                                                      onChange={event => setMessage(event.target.value)}
                                                      defaultValue={""} required/>
                        </div>
                        <div className="form-group col-md-12 text-center">
                            <button id="btn-cont" type="submit"
                                    className="btn slider_btn btn_hover hover_color mb-5">Enviar
                            </button>
                        </div>
                    </form>

                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3762.615760302173!2d-99.20749568562432!3d19.42900098688639!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMTnCsDI1JzQ0LjQiTiA5OcKwMTInMTkuMSJX!5e0!3m2!1ses-419!2scu!4v1595514529693!5m2!1ses-419!2scu"
                        style={{border: 0}} allowFullScreen aria-hidden="false" tabIndex={0} width="100%" height={450}
                        frameBorder={0}/>
                </div>
            </section>
            {/*============Contact area===========*/}
            {/*============Contact Info area===========*/}
            <section className="contact_info_area section_gap">
                <div className="">
                    <div className="contact_info">
                        <div className="contact_info_item">
                            <h4>Dirección</h4>
                            <p className="mb-0">Torre Esmeralda II, Piso 10
                                Col. Lomas de Chapultepec
                                C.P. 11000, Ciudad de México</p>
                        </div>
                        <div className="contact_info_item phone">
                            <h4>Teléfono</h4>
                            <a href="tel:556378-2805">(55) 6378-2805</a>
                        </div>
                        <div className="contact_info_item">
                            <h4>Correo electrónico</h4>
                            <a href="mailto:contacto@irstrat.com">contacto@irstrat.com</a>
                        </div>
                    </div>
                </div>
            </section>
            {/*============Contact Info area===========*/}
        </Layout>
    )
}

export default Contacto
